@import '@chialab/cells/lib/typography.css';
@import './variables.css';

.oblique {
    font-style: oblique;
}

h1,
.heading-1 {
    margin: 0;
    font-family: var(--font-sans);
    font-size: var(--font-size-9);
    font-weight: normal;
}

h2,
.heading-2 {
    margin: 0;
    font-family: var(--font-sans);
    font-size: var(--font-size-6);
    font-weight: 200;
}

h3,
.heading-3 {
    margin: 0;
    font-family: var(--font-sans);
    font-size: var(--font-size-4);
    font-weight: normal;
}

h4,
.heading-4 {
    margin: 0;
    font-family: var(--font-sans);
    font-size: var(--font-size-2);
    font-weight: normal;
}

h5,
.heading-5 {
    margin: 0;
    font-family: var(--font-sans);
    font-size: var(--font-size-1);
    font-weight: normal;
    line-height: 1.2;
}

pre,
.pre {
    font-family: var(--font-mono);
    font-size: var(--font-size-small);
    font-weight: lighter;
}

.ellipsis {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.capitalize::first-letter {
    text-transform: capitalize;
}
