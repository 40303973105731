@import './variables.css';

.dialog .scroller {
    --scrollbar-r: 0;
    --scrollbar-g: 0;
    --scrollbar-b: 0;
}

.dialog .dialog__container {
    width: calc(var(--column) * 2);
    max-width: calc(100% - calc(var(--gap) * 2));
}

.dialog--large .dialog__container {
    width: calc(var(--column) * 4);
}

.dialog .dialog__commands {
    margin: 0 var(--gap);
    padding: var(--gap) 0;
    border-top: solid 1px var(--grey-200);
}
