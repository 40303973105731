@import './variables.css';

.list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
}

.list > * + *:not(dna-spinner) {
    border-top: solid 1px var(--grey-300);
}

.list__column {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: calc(var(--gap) * 0.5);
    overflow: hidden;
}

.list__column > *,
.list__column > div > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.list .list__column {
    display: flex;
    flex: 1;
}

.list .list__column > *:empty {
    display: none;
}

.list .list__column > .column + .column {
    margin-left: auto;
}

.list .list__commands {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: calc(var(--gap) * 0.5);
}

.list .list__commands:empty {
    display: none;
}

.listitem {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--gap);
    width: 100%;
    margin: 0;
    padding: calc(var(--gap) * 0.5) 0;
}

.listitem--column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: calc(var(--gap) * 0.25);
    width: 100%;
    margin: 0;
    padding: calc(var(--gap) * 0.5) 0;
}

.listitem .list__poster {
    flex: none;
    width: 3em;
    height: 3em;
}

.listitem .listitem__commands {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: calc(var(--gap) * 0.5);
}

.list--description {
    display: grid;
    grid-template-columns: auto auto;
    gap: calc(var(--gap) * 0.5) var(--gap);
    align-items: baseline;
    margin: 0;
    padding: 0;
}

.list--description dt {
    padding: calc(var(--gap) * 0.5) 0;
    font-weight: bold;
}

.list--description dt::first-letter {
    text-transform: uppercase;
}

.list--description dd {
    margin: 0;
    padding: calc(var(--gap) * 0.5) 0;
    color: var(--grey-200);
}
