@import './variables.css';

.form {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    align-items: flex-start;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: calc(var(--gap) * 0.5);
}

.form-group.form-group--inline {
    flex-direction: row;
    align-items: center;
}

.form-group label::first-letter,
.form-group .form-label::first-letter,
.form-group .toggle__label::first-letter {
    text-transform: uppercase;
}

.form-group input,
.form-group textarea,
.form-group select {
    padding: calc(var(--padding) * 2);
    font-family: var(--font-sans);
    font-size: inherit;
    line-height: 1.375;
    border: solid 1px var(--grey-300);
    border-radius: var(--radius);
    resize: vertical;
}

.form-group input:disabled,
.form-group textarea:disabled,
.form-group select:disabled {
    background: var(--grey-300);
    border: solid 1px transparent;
    cursor: no-drop;
}

.form-group dna-toggle .toggle__wrapper {
    order: 1;
}

.form-group dna-toggle .toggle__label {
    margin: 0 var(--gap) 0 0;
}
