@import './variables.css';

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: var(--gap);
    grid-column: 1 / -1;
    white-space: nowrap;
    text-transform: lowercase;
}

.pagination ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--gap);
    margin: 0;
    padding: 0;
    list-style: none;
}

.pagination ul .disabled {
    display: none;
}
