@import '../../../css/variables.css';

cms-form-structure {
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: var(--gap);
    line-height: 1.5;
}

cms-form-structure dna-spinner {
    color: currentcolor;
}

cms-form-structure .form-structure__schema {
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

cms-form-structure a[aria-current='true'] {
    color: var(--color-accent, currentcolor);
}

cms-form-structure div svg {
    flex: none;
    width: var(--gap);
    height: var(--gap);
    margin-top: -0.6em;
    margin-right: var(--margin);
    fill: currentcolor;
}

cms-form-structure .form-structure__entry {
    display: flex;
    align-items: baseline;
    width: 100%;
    overflow: hidden;
}

cms-form-structure .form-structure__entry > a {
    overflow: hidden;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
}

cms-form-structure .form-structure__entry > span {
    margin-left: var(--margin);
    opacity: 0.5;
}

cms-form-structure .form-structure__entry > a * {
    margin: 0;
    padding: 0;
    list-style: none;
}

cms-form-structure .form-structure__branch {
    display: flex;
    flex-direction: column;
    width: 100%;
}

cms-form-structure .form-structure__branch > .form-structure__schema > .form-structure__branch {
    padding-left: calc(var(--gap) + var(--margin));
}

cms-form-structure .form-structure__leaf {
    display: flex;
    flex-direction: row;
    align-items: center;
}
