@import '@chialab/dna-media-player';
@import '../../../css/variables.css';

cms-content-poster {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--grey-200);
}

cms-content-poster img,
cms-content-poster video {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    -webkit-user-drag: none;
}

cms-content-poster dna-media-player,
cms-content-poster dna-media-player .player-wrapper,
cms-content-poster dna-media-player .player-media {
    width: 100%;
}

cms-content-poster dna-media-player[type='video'],
cms-content-poster dna-media-player[type='video'] .player-wrapper,
cms-content-poster dna-media-player[type='video'] .player-media {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

cms-content-poster[compact] dna-media-player[type='video'] .player-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

cms-content-poster dna-media-player[type='audio'] {
    margin: 0 var(--spacing);
}

cms-content-poster[compact] dna-media-player .player-control-bar {
    width: auto;
    padding: 0;
}

cms-content-poster[compact] dna-media-player .player-media::after {
    display: none;
}

cms-content-poster svg {
    display: block;
    flex: none;
    width: 45%;
    height: auto;
    fill: var(--grey-700);
}

cms-content-poster dna-media-player .player-control-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

cms-content-poster dna-media-player[type='audio'] .player-control-bar {
    margin: auto;
}

cms-content-poster[compact] dna-media-player[type='video']:not([controls='visible']) .player-control-bar {
    position: relative;
    padding: 0;
    background-color: #000;
    border-radius: 50%;
}

cms-content-poster[compact] dna-media-player[type='video'] .player-control-bar::before {
    display: none;
}

cms-content-poster[compact] dna-media-player[type='video'] .player-control-bar .player-slider {
    flex: 1;
    width: auto;
    margin: 0 var(--spacing);
}
