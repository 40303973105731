@import './variables.css';

.table,
table {
    width: 100%;
    border-spacing: 0;
    grid-column: 1 / -1;
}

.table th,
table th {
    position: sticky;
    top: 0;
    z-index: 2;
    padding: calc(var(--padding) * 4) calc(var(--padding) * 2);
    font-weight: normal;
    text-align: left;
    vertical-align: bottom;
    background: var(--grey-900);
    border-bottom: solid 2px var(--color-accent, #000);
}

.table th a:hover,
table th a:hover {
    text-decoration: none;
}

.table th .asc,
table th .asc,
.table th .desc,
table th .desc {
    display: inline-flex;
    align-items: center;
    font-weight: bold;
}

.table th .asc::after,
table th .asc::after,
.table th .desc::after,
table th .desc::after {
    margin-left: calc(var(--margin) * 3);
    font-size: 0.875em;
    line-height: 0;
}

.table th .asc::after,
table th .asc::after {
    content: '▾';
    transform: rotate(180deg);
    transform-origin: center;
}

.table th .desc::after,
table th .desc::after {
    content: '▾';
}

.table td,
table td {
    padding: calc(var(--padding) * 3) calc(var(--padding) * 2);
    text-align: left;
    border-bottom: solid 1px var(--grey-500);
}

.table td ul,
table td ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

.table tr:hover td,
table tr:hover td {
    background: rgba(255, 255, 255, 0.05);
}

.table td.commands,
table td.commands {
    width: 0;
}

.table td.commands form,
table td.commands form {
    margin: 0;
}
