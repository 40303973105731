@import '../../../css/variables.css';

cms-image-spots {
    position: relative;
    display: block;
    width: 100%;
    grid-column: 1 / -1;
}

cms-image-spots img {
    display: block;
    width: 100%;
    cursor: crosshair;
    -webkit-user-drag: none;
}

cms-image-spots .image__spot {
    position: absolute;
    z-index: 1;
    width: fit-content;
    padding: 0 calc(var(--gap) * 0.5);
    color: #000;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
}
