@import '../../../css/variables.css';

cms-quick-explorer {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    max-height: 60vh;
    margin-bottom: var(--gap);
}

cms-quick-explorer form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap);
}

cms-quick-explorer .explorer__view-mode {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: calc(var(--gap) * 0.5);
}

cms-quick-explorer .explorer__view-mode [aria-pressed='false'] {
    opacity: 0.5;
}

cms-quick-explorer .explorer__scroller {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

cms-quick-explorer .explorer__scroller > dna-spinner {
    width: 3em;
    height: 3em;
    margin: var(--gap) auto;
}

cms-quick-explorer cms-content-card {
    line-height: 1.25;
}

cms-quick-explorer [data-filtered] {
    opacity: 0.5;
    filter: grayscale(100%);
}
