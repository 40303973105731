@import '../../../css/variables.css';

cms-drawer:not([collapsed]) {
    position: sticky;
    top: calc(var(--gap) * 0.5);
    display: flex;
    flex-direction: column;
    grid-column: span 3;
    height: calc(100vh - (var(--gap) * 8));
    overflow: hidden;
}

cms-drawer [data-action='open'] {
    position: fixed;
    top: var(--gap);
    right: var(--gap);
    z-index: 999;
    font-size: 1.5em;
    box-shadow: var(--shadow-2);
}

cms-drawer [is='cms-tab'] {
    min-height: 52px;
    overflow: hidden;
}

cms-drawer [is='cms-tab'].full-size[open] {
    height: 100%;
}

cms-drawer [is='cms-tab'] > .tab-content {
    position: relative;
    max-height: calc(100% - 50px - var(--gap));
    padding: 0 calc(var(--gap) * 0.5);
}

cms-drawer [is='cms-tab'].full-size[open] > .tab-content {
    height: calc(100% - 50px - var(--gap));
}

cms-drawer cms-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

cms-drawer dna-sidenav .sidenav__drawer {
    width: calc(3 * var(--column) + 2 * var(--gap));
    max-width: calc(100vw - var(--gap) * 2);
    padding: 0 calc(var(--gap) * 0.5 + 20px) 0 calc(var(--gap) * 0.5);
}
