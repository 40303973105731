@import '@chialab/cells/lib/colors.css';
@import './variables.css';

.message {
    flex: none;
    padding: calc(var(--padding) * 3);
    overflow: hidden;
    word-break: break-word;
    border-bottom: solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.message.hidden {
    display: none;
}

.message.success {
    color: var(--color-success--text);
    background: var(--color-success);
}

.message.error {
    color: var(--color-fail--text);
    background: var(--color-fail);
}
