@import '@chialab/cells/lib/colors.css';
@import '@chialab/cells/lib/typography.css';
@import '../../../css/variables.css';

cms-file-upload {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--column), 1fr));
    gap: var(--gap);
}

cms-file-upload .upload__preview {
    position: relative;
    overflow: hidden;
    background: var(--grey-100);
    border-radius: calc(var(--radius) * 2);
    aspect-ratio: 1 / 1;
}

cms-file-upload .upload__preview input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: 100%;
    font-size: 0;
    cursor: pointer;
    opacity: 0;
}

cms-file-upload .upload__preview cms-content-poster {
    width: 100%;
    aspect-ratio: 1 / 1;
}

cms-file-upload .upload__fieldset {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    padding: var(--gap) calc(var(--gap) * 0.5);
    color: #fff;
    background: #000;
}

cms-file-upload .upload__fieldset[data-progress] {
    background: #837600;
    transition: background 250ms ease-out;
}

cms-file-upload .upload__fieldset[data-progress='-1'] {
    color: var(--color-fail--text);
    background: var(--color-fail);
}

cms-file-upload .upload__fieldset[data-progress='100'] {
    color: var(--color-success--text);
    background: var(--color-success);
}

cms-file-upload .upload__fieldset .form-group {
    width: 100%;
}

cms-file-upload .upload__progress {
    margin-top: var(--margin);
    font-size: var(--font-size-small);
}
