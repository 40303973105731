@import './variables.css';

[is='dna-button'],
[is='dna-button-link'] {
    justify-content: center;
    min-width: 6rem;
    padding: calc(var(--padding) * 3) calc(var(--padding) * 4);
    font-family: var(--font-sans);
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    border-radius: var(--radius);
}

[is='dna-button'][variant='primary'],
[is='dna-button-link'][variant='primary'],
[is='dna-button'][variant='action:primary'],
[is='dna-button-link'][variant='action:primary'] {
    color: #000;
    background-color: #fff;
}

[is='dna-button'][variant='secondary'],
[is='dna-button-link'][variant='secondary'],
[is='dna-button'][variant='action:secondary'],
[is='dna-button-link'][variant='action:secondary'] {
    color: inherit;
    background-color: transparent;
    border: solid 1px currentcolor;
}

button:not(:disabled),
[class*='btn-']:not(:disabled) {
    cursor: pointer;
}

[is='dna-button']:disabled,
[is='dna-button-link']:disabled {
    color: var(--grey-300);
}

[is='dna-button'][variant^='action'],
[is='dna-button-link'][variant^='action'] {
    min-width: 0;
    min-height: 0;
    padding: calc(var(--padding) * 2);
    border-radius: 50%;
}

[is='dna-button'][variant='action'] svg,
[is='dna-button-link'][variant='action'] svg {
    display: block;
    fill: currentcolor;
}

[is='dna-button'][variant='action'] > div:not(:empty),
[is='dna-button-link'][variant='action'] > div:not(:empty) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
}

[is='dna-button-link']:hover {
    text-decoration: none;
}
