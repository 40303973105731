@import '../../../css/variables.css';

cms-media-spots {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-column: 1 / -1;
    gap: calc(var(--spacing) * 0.5);
    width: 100%;
    margin-bottom: calc(var(--spacing) * 0.5);
}

cms-media-spots dna-media-player {
    width: 100%;
}

cms-media-spots ul {
    width: 100%;
}

cms-media-spots ul li {
    width: 100%;
}
