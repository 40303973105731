@import '../../../css/variables.css';

[is='cms-form-schema'] {
    grid-column: 1 / -1;
    width: 100%;
}

[is='cms-form-schema'] .form__inputs:empty {
    display: none;
}

[is='cms-form-schema'] *:target {
    animation: highlight 5s ease-in-out;
}

@keyframes highlight {
    0% {
        outline: 0;
    }

    1%, 99% {
        outline: var(--outline);
        outline-color: var(--outline-color);
        outline-offset: var(--outline-offset);
    }

    100% {
        outline: 0;
    }
}

[is='cms-form-schema'] > .form__contents {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--column), 1fr));
    grid-column: 1 / -1;
    width: 100%;
    margin-bottom: var(--gap);
}

[is='cms-form-schema'] > .form__contents > *:not(:empty) {
    padding-bottom: var(--gap);
}

[is='cms-form-schema'] > .form__contents > .form__fields {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--column), 1fr));
    gap: var(--gap);
    grid-column: 1 / -1;
    width: 100%;
}

[is='cms-form-schema'] > .form__contents > .form__associations {
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;
    gap: var(--gap);
    width: 100%;
}

[is='cms-form-schema'] > .form__contents > .form__fields > cms-content-poster {
    grid-column: 1 / -1;
    aspect-ratio: 16 / 9;
}

[is='cms-form-schema'] > .form__contents > .form__fields > .form-group.form-group--medium {
    grid-column: span 2;
}

[is='cms-form-schema'] > .form__contents > .form__fields > .form-group.form-group--large {
    grid-column: 1 / -1;
}

[is='cms-form-schema'] > .form__contents > .form__fields > .form-group dna-toggle {
    margin: calc(var(--gap) * 0.5) 0;
}

[is='cms-form-schema'] .form-details {
    grid-column: 1 / -1;
}

[is='cms-form-schema'] .form-details--large {
    grid-column: 1 / -1;
}

[is='cms-form-schema'] .form-association {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--column), 1fr));
    grid-column: span 6;
    align-items: flex-start;
    gap: var(--gap);
}

[is='cms-form-schema'] .form-association__details > summary {
    display: flex;
    flex: none;
    flex-direction: row;
}

[is='cms-form-schema'] .form-association__toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: var(--gap) 0;
}

[is='cms-form-schema'] .form-association__toolbar > button:first-of-type {
    margin-left: auto;
}

[is='cms-form-schema'] .form-association__count {
    font-size: 0.875em;
    opacity: 0.5;
}

[is='cms-form-schema'] .form-association .form-association__controls {
    display: flex;
    gap: var(--gap);
    grid-column: 1 / -1;
    justify-content: end;
    align-items: center;
}

[is='cms-form-schema'] .form-association > .form-association__content {
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;
    gap: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
}

[is='cms-form-schema'] .form-association > .form-association__content > * + * {
    border-top: solid 1px rgba(255, 255, 255, 0.2);
}

[is='cms-form-schema'] .form-association .form-association__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: baseline;
    gap: var(--gap);
}

[is='cms-form-schema'] .form-association .form-association__path {
    font-size: var(--font-size-small);
    line-height: 1.2;
    word-break: break-word;
    opacity: 0.5;
}

[is='cms-form-schema'] .form-association .tab-summary > *::first-letter {
    text-transform: unset;
}

[is='cms-form-schema'] .form-association .form-association__name {
    display: inline-block;
}

[is='cms-form-schema'] .form-association .form-association__name::first-letter {
    text-transform: uppercase;
}

[is='cms-form-schema'] .form-association .form-association__path * {
    display: inline;
    margin: 0;
    padding: 0;
    list-style: none;
}

[is='cms-form-schema'] .form-association .layout-commands {
    display: flex;
    flex-direction: row;
    gap: var(--gap);
    margin-bottom: var(--gap);
}

[is='cms-form-schema'] .form-association > .form-association__commands {
    position: absolute;
    top: calc(var(--gap) * 0.5);
    right: calc(var(--gap) * 0.5);
}

dna-html-editor cms-text-reference {
    display: inline-flex;
    max-width: 14em;
    margin: calc(var(--margin) * 2);
    padding: 0 var(--padding);
    overflow: hidden;
    background: #fff;
    border: solid 1px var(--grey-500);
    border-radius: var(--radius);
}

[is='cms-form-schema'][compact] {
    display: flex;
    flex-direction: row;
    align-items: center;
}

[is='cms-form-schema'][compact] .form__contents {
    display: flex;
    flex-direction: row;
    align-items: center;
}

[is='cms-form-schema'][compact] .form__toolbar {
    order: 1;
}

[is='cms-form-schema'][compact] .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
}

[is='cms-form-schema'][compact] .form__fields {
    display: flex;
    flex-direction: row;
}

[is='cms-form-schema'] .from-group__tabs dna-tabs .tabs__header {
    justify-content: flex-end;
    order: 1;
}

[is='cms-form-schema'] .from-group__tabs dna-tabs .tabs__header::after {
    display: none;
}

[is='cms-form-schema'] .from-group__tabs dna-tabs .tabs__header [is='dna-button'][role='tab'] {
    min-width: 0;
    padding: 1em;
    border: 0;
}

[is='cms-form-schema'] .from-group__tabs dna-tabs .tabs__header [is='dna-button'][aria-selected='true']::before {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--color-accent);
    border-bottom-width: 0;
}

[is='cms-form-schema'] .from-group__tabs dna-tabs .tabs__wrapper {
    margin-top: 0;
    overflow: visible;
}

[is='cms-form-schema'] [is='cms-form-schema'] > .form__contents > .form__fields,
[is='cms-form-schema'] [is='cms-form-schema'] > .form__contents > .form__associations {
    padding-left: var(--gap);
    border-left: solid 1px var(--color-accent);
}

[is='cms-form-schema'] dna-html-editor {
    max-height: 80vh;
    overflow: auto;
}

[is='cms-form-schema'] dna-html-editor .html-editor__toolbar {
    position: sticky;
    top: 0;
    z-index: 11;
}

[is='cms-form-schema'] cms-form-items {
    --sticky-index: 0;
}

[is='cms-form-schema'] cms-form-items * {
    scroll-margin: calc(var(--scroll-margin, 0) + (calc(var(--gap) * 3.25) * var(--sticky-index)));
}

[is='cms-form-schema'] cms-form-items > details > summary {
    position: sticky;
    top: calc(calc(var(--gap) * 3.25) * var(--sticky-index));
    z-index: calc(106 - var(--sticky-index));
    background: var(--grey-900);
}

[is='cms-form-schema'] [is='cms-form-schema'] cms-form-items {
    --sticky-index: 1;
}

[is='cms-form-schema'] [is='cms-form-schema'] [is='cms-form-schema'] cms-form-items {
    --sticky-index: 2;
}

[is='cms-form-schema'] [is='cms-form-schema'] [is='cms-form-schema'] [is='cms-form-schema'] cms-form-items {
    --sticky-index: 3;
}

[is='cms-form-schema'] [is='cms-form-schema'] [is='cms-form-schema'] [is='cms-form-schema'] [is='cms-form-schema'] cms-form-items {
    --sticky-index: 4;
}

[is='cms-form-schema'] [is='cms-form-schema'] [is='cms-form-schema'] [is='cms-form-schema'] [is='cms-form-schema'] [is='cms-form-schema'] cms-form-items {
    --sticky-index: 5;
}

[is='cms-form-schema'] .clipboard-button {
    font-style: italic;
    border-radius: 2em;
}

[is='cms-form-schema'] .clipboard-button span {
    font-size: 0.75em;
    opacity: 0.7;
}
