@import '../../../css/variables.css';

[is='cms-button-upload'] {
    position: relative;
}

[is='cms-button-upload'] input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    font-size: 0;
    cursor: pointer;
    opacity: 0;
}
