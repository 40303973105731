@import '../../../css/variables.css';

cms-text-reference {
    display: inline-flex;
    max-width: 14em;
    margin: calc(var(--margin) * 2);
    padding: 0 var(--padding);
    overflow: hidden;
    background: #fff;
    border: solid 1px var(--grey-500);
    border-radius: var(--radius);
}
