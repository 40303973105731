@import '../../../css/variables.css';

cms-preview {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
}

cms-preview .preview__switch {
    display: flex;
    flex-direction: row;
    gap: calc(var(--spacing) * 0.5);
    justify-content: flex-end;
}

cms-preview .preview__frame {
    position: relative;
    width: 100%;
    height: 100%;
    color: var(--color-accent, #000);
    background: #fff;
}

cms-preview iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

cms-preview[\:loading] iframe {
    opacity: 0;
}

cms-preview dna-spinner {
    position: absolute;
    top: var(--column);
    left: 50%;
    transform: translateX(-50%);
}
