@import './variables.css';

.scroller {
    --scrollbar-r: 255;
    --scrollbar-g: 255;
    --scrollbar-b: 255;
    overflow: auto;
}

.scroller::-webkit-scrollbar {
    width: var(--scrollbar-size);
    height: var(--scrollbar-size);
}

.scroller::-webkit-scrollbar-thumb {
    background: rgba(var(--scrollbar-r), var(--scrollbar-g), var(--scrollbar-b), 0.25);
    background-clip: content-box;
    border: calc(var(--scrollbar-size) * 0.25) solid transparent;
    border-radius: 15px;
}

.scroller::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--scrollbar-r), var(--scrollbar-g), var(--scrollbar-b), 0.5);
    background-clip: content-box;
    border: calc(var(--scrollbar-size) * 0.25) solid transparent;
    border-radius: 15px;
}

.scroller::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 15px;
}
