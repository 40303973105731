@import '@chialab/cells/lib/typography.css';
@import './variables.css';

.block {
    --r: 61;
    --g: 64;
    --b: 66;

    position: relative;
    z-index: 1;
    display: flex;
    flex: none;
    flex-direction: column;
    justify-content: space-between;
    width: var(--column);
    height: var(--block);
    padding: 12px;
    color: inherit;
    text-transform: capitalize;
    text-decoration: none;
    background-color: rgb(var(--r), var(--g), var(--b));
    background-clip: border-box;
    border-bottom: solid 24px rgba(0, 0, 0, 0.25);
}

.block-w {
    min-width: var(--block);
}

.block-h {
    min-height: var(--block);
}

.block:hover {
    text-decoration: none;
}

.block::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(100, 100, 100, 0.2);
    opacity: 0;
    transition: opacity 150ms ease-out;
}

a.block:hover::after,
button.block:hover::after {
    opacity: 0.5;
}

a.block:active::after,
button.block:active::after {
    opacity: 1;
}

.block img {
    width: calc(var(--column) * 0.25);
    filter: grayscale(100%) invert(100%);
}

.block--nav {
    --r: 61;
    --g: 64;
    --b: 66;
    --border: calc(var(--margin) * 3);

    position: relative;
    display: block;
    flex: none;
    width: 66px;
    max-width: 66px;
    margin-top: var(--border);
    padding-top: calc(var(--padding) * 2);
    padding-right: calc(var(--padding) * 4);
    color: currentcolor;
    font-size: var(--font-size-xsmall);
    line-height: 1;
    text-transform: lowercase;
    text-decoration: none;
    word-break: break-word;
    hyphens: auto;
}

.block--nav:hover {
    text-decoration: none;
}

.block--nav::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    display: block;
    width: 100%;
    height: var(--border);
    background-color: rgb(var(--r), var(--g), var(--b));
}

.block--nav[aria-current='page']::before {
    height: calc(var(--gap) + var(--border));
}

.block--tag {
    --r: 61;
    --g: 64;
    --b: 66;

    display: inline-block;
    flex: none;
    padding: 6px 12px;
    color: #fff;
    font-size: var(--font-size-xsmall);
    line-height: 1;
    background-color: rgb(var(--r), var(--g), var(--b));
    border-radius: calc(var(--radius) * 2);
}

.block--tag::first-letter {
    text-transform: capitalize;
}

.flash {
    width: var(--column);
}
