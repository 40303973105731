@import './variables.css';

.details,
.tab {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.details > summary,
.tab > summary {
    display: flex;
    flex: none;
    flex-direction: row;
}

.summary,
.tab > summary > .tab-summary {
    display: flex;
    flex: none;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: calc(var(--padding) * 4) 0 calc(var(--padding) * 2) 0;
    border-bottom: solid 2px var(--grey-500, #ccc);
    user-select: none;
}

.tab > summary > .tab-summary {
    cursor: pointer;
}

.summary > *::first-letter,
.tab > summary > .tab-summary > *::first-letter {
    text-transform: uppercase;
}

.tab[open] > summary > .tab-summary {
    border-bottom-color: var(--color-accent, #000);
}

.tab[accordion] + .tab[accordion] > summary > .tab-summary {
    border-top: solid 2px transparent;
}

.tab[open][accordion] + .tab[accordion] > summary > .tab-summary {
    border-top-color: var(--color-accent, #000);
}

.tab > .tab-content {
    margin: var(--gap) 0;
}

.tab > summary > .tab-summary > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: calc(var(--gap) * 0.5);
    fill: currentcolor;
}

.tab > summary > .tab-summary > .tab-nav {
    display: flex;
    flex-direction: row;
    gap: var(--gap);
    align-items: center;
    margin-left: auto;
}

.tab > summary > .tab-summary:hover > svg {
    fill: var(--color-accent, #000);
}

.tab:not([open]) > summary > .tab-summary > .tab__toggle-close {
    display: none;
}

.tab[open] > summary > .tab-summary > .tab__toggle-open {
    display: none;
}

dna-tabs {
    width: 100%;
}

dna-tabs .tabs__header [is='dna-button'][role='tab'] {
    padding: var(--gap) calc(var(--gap) * 2);
}

dna-tabs dna-tab {
    position: relative;
    max-width: 100%;
    padding: 0;
}

.details {
    appearance: none;
}

details:not([open]) > *:not(summary),
.details:not([open]) > *:not(summary) {
    display: none;
}
