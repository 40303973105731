@import './variables.css';

.gap--half {
    gap: calc(var(--gap) * 0.5);
}

.scroller > .grid {
    grid-template-columns: repeat(auto-fill, minmax(calc(var(--column) - var(--scrollbar-size)), 1fr));
}

.shell {
    display: grid;
    gap: var(--gap);
    grid-template-columns: repeat(auto-fill, var(--column));
    grid-auto-rows: min-content;
    padding: calc(var(--padding) * 4);
}

.aside {
    position: sticky;
    top: var(--gap);
    flex-wrap: nowrap;
    height: calc(100vh - var(--column) * 0.5 - var(--gap) * 3);
}

.footer {
    position: sticky;
    bottom: var(--gap);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(var(--column) * 0.5);
}

.main {
    grid-column: 2 / -1;
    padding-bottom: calc(var(--gap) * 5);
}

.content {
    grid-column: 1 / -1;
    width: calc(var(--column) * 8 + var(--gap) * 7);
    max-width: 100%;
}

.topnav {
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--column));
    gap: var(--gap);
    align-items: flex-start;
    min-height: var(--block);
}

.topnav nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-column: 1 / -1;
    align-items: flex-start;
    min-height: calc(var(--block) - var(--gap));
}

.topnav > div {
    margin-top: auto;
    padding-bottom: var(--gap);
}

/* 176 * 4 + 16 * 5 (4 columns) */
@media (max-width: 783px) {
    .shell {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: var(--gap);
    }

    .aside {
        position: relative;
        top: 0;
        height: auto;
    }

    .footer {
        position: relative;
        bottom: 0;
    }
}
