@import './variables.css';

.card {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    color: #000;
    background: #fff;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: var(--radius);
}

.card .card__poster {
    position: relative;
    overflow: hidden;
    aspect-ratio: 16 / 9;
}

.card .card__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--gap);
    margin-bottom: auto;
    padding: calc(var(--padding) * 2);
    overflow: hidden;
    line-height: 1.5;
}

.card .card__content p {
    margin: 0;
}

.card .card__content > * {
    max-width: 100%;
    line-height: 1.2;
}

.card .card__content > *:empty {
    display: none;
}

.card .card__content h5 {
    font-weight: bold;
}

.card .card__commands {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: calc(var(--gap) / 2);
    margin-top: var(--gap);
    padding: calc(var(--padding) * 2);
}

.card:not([compact]) .content-card__main-field {
    font-weight: bold;
    word-break: break-word;
}
