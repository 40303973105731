@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,300;0,400;1,300;1,400&family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&family=IBM+Plex+Serif:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');
@import '@chialab/cells';
@import '@chialab/dna-backdrop';
@import '@chialab/dna-button';
@import '@chialab/dna-color-picker';
@import '@chialab/dna-date-time';
@import '@chialab/dna-dialog';
@import '@chialab/dna-dropdown';
@import '@chialab/dna-dragdrop';
@import '@chialab/dna-code-editor';
@import '@chialab/dna-html-editor';
@import '@chialab/dna-toggle';
@import '@chialab/dna-sidenav';
@import '@chialab/dna-spinner';
@import '@chialab/dna-tabs';
@import '@chialab/dna-menu';
@import '../js/Elements/ButtonUpload/ButtonUpload.css';
@import '../js/Elements/TextReference/TextReference.css';
@import '../js/Elements/ContentPoster/ContentPoster.css';
@import '../js/Elements/FormSchema/FormSchema.css';
@import '../js/Elements/Drawer/Drawer.css';
@import '../js/Elements/FormStructure/FormStructure.css';
@import '../js/Elements/FileUpload/FileUpload.css';
@import '../js/Elements/ImageSpots/ImageSpots.css';
@import '../js/Elements/MediaSpots/MediaSpots.css';
@import '../js/Elements/QuickCreate/QuickCreate.css';
@import '../js/Elements/QuickExplorer/QuickExplorer.css';
@import '../js/Elements/Preview/Preview.css';
@import '../js/Elements/Raw/Raw.css';
@import '../js/Elements/Visible/Visible.css';
@import './variables.css';
@import './typography.css';
@import './layouts.css';
@import './buttons.css';
@import './forms.css';
@import './scrollbar.css';
@import './tabs.css';
@import './dialogs.css';
@import './blocks.css';
@import './cards.css';
@import './lists.css';
@import './tables.css';
@import './messages.css';
@import './pagination.css';

* {
    box-sizing: border-box;
    scroll-margin: var(--gap);
}

html {
    position: relative;
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: var(--light-0);
    font-family: var(--font-sans);
    background: var(--grey-900);
    scroll-behavior: smooth;
}

img {
    max-width: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.actions {
    flex: 1 auto;
    flex-wrap: nowrap;
    overflow: auto;
}

.actions:empty {
    display: none;
}

.profile {
    margin-top: auto;
}

dna-sortable > .sortable__list > dna-draggable {
    padding: 0;
    border: 0;
}

dna-draggable[\:dragging] {
    box-shadow: 0 3px 6px 0 rgba(66, 69, 72, 0.4);
}

dna-draggable[role='listitem'][clone][\:dragging]:not([\:cloned]) {
    padding: calc(var(--gap) * 0.5);
    overflow: hidden;
    color: #000;
    background: #fff;
    border: 0;
    border-radius: var(--radius);
}

dna-dialog {
    z-index: 9999999;
}

dna-sidenav {
    --color-surface: var(--grey-900);

    position: fixed;
}

dna-sidenav .sidenav__content {
    width: 100%;
}

dna-sidenav .sidenav__drawer {
    width: 960px;
    max-width: 50vw;
    pointer-events: all;
}

dna-sidenav cms-preview {
    flex: 1;
    width: 100%;
    height: 100%;
}

.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo img {
    display: block;
    width: auto;
    height: 0.75em;
}

.logo .logotype {
    display: block;
    margin-left: 0.5em;
    padding-left: 0.5em;
    border-left: solid 1px #fff;
}

[is='dna-date-time'] {
    display: inline-block;
    min-width: 6em;
}

dna-menu {
    min-width: 10rem;
    padding: calc(var(--gap) * 0.5) 0;
    color: var(--color-primary);
}

dna-menu [is='dna-button-link'],
dna-menu [is='dna-button'] {
    justify-content: flex-start;
    text-transform: none;
}

[is='dna-color-picker'] {
    border-radius: 50%;
}

html.submitting body {
    pointer-events: none;
}

html.submitting body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    cursor: wait;
    pointer-events: all;
}

.select-all {
    user-select: all;
}

.break-all {
    word-break: break-all;
}
